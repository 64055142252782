import Swiper, { Autoplay } from "swiper";

function setupBannerMainCarousels() {
  const bannerCarousels = document.querySelectorAll(".js-bannerCarousel");

  bannerCarousels.forEach(function (banner) {
    if (banner.querySelectorAll(".swiper-slide").length > 1) {
      new Swiper(banner, {
        modules: [Autoplay],
        slidesPerView: 1,
        spaceBetween: 0,
        centeredSlides: true,
        speed: 750,
        autoplay: {
          delay: 5000,
        },
        loop: true,
        breakpoints: {
          1024: {
            speed: 1500,
          },
        },
        on: {
          init: (s) => {
            const slideCountText = [
              ...banner.querySelectorAll(".js-slideCount"),
            ];
            if (slideCountText.length > 0) {
              slideCountText.forEach(function (t) {
                t.textContent = `${s.slides.length - 2}`.padStart(2, "0");
              });
            }
          },
        },
      });
    } else {
      banner
        .querySelector(".swiper-slide")
        .classList.add("swiper-slide-active");
    }
  });
}

function setupBannerFooterCarousels() {
  const bannerFooterCarousels = document.querySelectorAll(
    ".js-bannerFooterCarousel"
  );
  bannerFooterCarousels.forEach(function (c) {
    const carousel = new Swiper(c, {
      modules: [Autoplay],
      slidesPerView: 1,
      spaceBetween: 0,
      centeredSlides: true,
      speed: 1000,
      autoplay: {
        delay: 2000,
      },
      loop: true,
      breakpoints: {
        1024: {
          loop: false,
          slidesPerView: "auto",
          autoplay: false,
          centeredSlides: false,
        },
      },
    });
  });
}

(function () {
  setupBannerMainCarousels();
  setupBannerFooterCarousels();
})();
